import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './HomeScreen.css'; // Import your CSS file for styling

const Home = () => {
  const navigate = useNavigate();
  // Sample data for the list of documents
  const documentData = [
    { id: '1', title: 'Tài Liệu Nhập Việc' },
    { id: '2', title: 'Tài Liệu Kinh Doanh' },
    { id: '3', title: 'Tài liệu Đào Tạo' },
    { id: '4', title: 'Tài liệu Sản Xuất' },
    { id: '5', title: 'Tài liệu Chế Biến' },
    { id: '6', title: 'Tài liệu PCCC' },
    { id: '7', title: 'Tài liệu An Toàn' },
    { id: '8', title: 'Tài liệu Thông Tin' },
    { id: '9', title: 'Tài liệu Nhập Khẩu' },
    { id: '10', title: 'Tài liệu Xuất Khẩu' },
    { id: '11', title: 'Quy định làm việc' },
    { id: '12', title: 'Quy trình báo cáo' },

    // Add more documents as needed
  ];

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <div>
      <h1>Danh Sách Tài Liệu</h1>
      <h2>Lưu Hành Nội Bộ</h2>
      <ul>
        {documentData.map((item) => (
          <li key={item.id}>
            <Link to={`/tailieu`}>{item.title}</Link>
          </li>
        ))}
      </ul>
      <button onClick={handleLogout}>Đăng Xuất</button>
    </div>
  );
};

export default Home;
