import React from 'react';
import RootComponent from './components/index';

function App() {
  return (
    <RootComponent />
  );
}

export default App;
