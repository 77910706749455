import React from 'react';

const Tailieu = ({ documentTitle }) => {
  return (
    <div style={styles.container}>
      <p style={styles.centerText}>ỨNG DỤNG ĐANG PHÁT TRIỂN</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100vh',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  centerText: {
    fontSize: '18px',
    // Add any other styles as needed
  },
};

export default Tailieu;
