import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginScreen.css'; // Import your CSS file for styling

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleLogin = () => {
    // Check if the entered email and password match the predefined values
    if (email === '1' && password === '1') {
      // Handle successful login
      navigate('/home');
    } else {
      // Handle unsuccessful login
      setErrorText('Đăng nhập thất bại. Vui lòng nhập đúng email và mật khẩu.');
    }
  };

  return (
    <div className="background">
      <div className="container">
        <img className="logo" src={require('../img/logo.png')} alt="Logo" />
        <h1 className="title">ĐĂNG NHẬP</h1>
        <input
          className="input"
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Mật Khẩu"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="loginButton" onClick={handleLogin}>
          ĐĂNG NHẬP
        </button>
        <p className="errorText">{errorText}</p>
        <p className="registerText">
          Bạn chưa có tài khoản?{' '}
          <span className="registerLink" onClick={() => navigate('/signup')}>
            Đăng ký ngay
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginScreen;
