import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignupScreen.css';

const SignupScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmCode, setCode] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleSignup = () => {
    if (confirmCode === '6688') {
      if (email && password && confirmPassword && password === confirmPassword) {
        navigate('/login'); // Use navigate instead of history.push
      } else {
        setErrorText('Đăng ký thất bại. Vui lòng nhập đúng thông tin.');
      }
    } else {
      setErrorText('Mã xác nhận không đúng. Vui lòng thử lại.');
    }
  };

  return (
    <div className="background">
      <div className="container">
        <img className="logo" src={require('../img/logo.png')} alt="Logo" />
        <h1 className="title">ĐĂNG KÝ</h1>
        <input
          className="input"
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Mật Khẩu"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Xác Nhận Mật Khẩu"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <input
          className="inputcode"
          type="text"
          placeholder="Mã Xác Nhận Nội Bộ"
          value={confirmCode}
          onChange={(e) => setCode(e.target.value)}
        /><br></br>
        <button className="signupButton" onClick={handleSignup}>
          ĐĂNG KÝ
        </button>
        <p className="errorText">{errorText}</p>
        <p className="registerText">
          Bạn đã có tài khoản?{' '}
          <span className="registerLink" onClick={() => navigate('/login')}>
            Đăng nhập ngay
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignupScreen;
