import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignupScreen from './SignupScreen';
import LoginScreen from './LoginScreen';
import HomeScreen from './HomeScreen';
import TaiLieuScreen from './Tailieu';

function RootComponent() {
  return (
    <Router>
      <Routes>
        {/* Place the LoginScreen route first */}
        <Route path="/" element={<LoginScreen />} />
        
        {/* Other routes */}
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/tailieu" element={<TaiLieuScreen />} />
      </Routes>
    </Router>
  );
}

export default RootComponent;
